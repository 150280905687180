.__package_list_view-packages-swiper {
  position: relative;

  .custom-buttons {
    position: absolute;
    right: 0;
    top: -44px;
    width: 96px;
    height: 40px;
    justify-content: space-between;
    align-items: center;

    .swiper-button-prev, .swiper-button-next {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;

      width: 40px;
      height: 40px;
      font-size: 42px;
      line-height: 1;
      &:after {
        content: '';
      }
    }
  }

  .swiper-slide {
    color: var(--charcoal);
    transition: all .15s cubic-bezier(0.4, 0, 0.2, 1);

    &.active {
      background-color: var(--honolulu-blue);
      color: var(--white);
    }
  }
}

.most-preferred-package-slide-prev {
  &.swiper-button-disabled { opacity: 0 !important; }
}
.most-preferred-package-slide-next {
  &.swiper-button-disabled { opacity: 0 !important; }
}

.__package_list_view-package-list-swiper {
  .swiper-wrapper {
    // align-items: stretch;
    padding-bottom: 5px;

    .swiper-slide {
      display: flex;
      height: unset;
    }
  }
}